<template>
  <div class="prompts-panel">
    <a-input v-model="text" style=" margin-bottom: 10px;" ref="search"/>
    <a-spin :spinning="spinning" size="large">
      <div class="prompts-container" :style="{height: panelHeight + 'px'}">
        <div v-for="item in menus" class="list" :key="item.id" @click="clickPrompt(item)">
          <div class="title">{{ item.title }}</div>
          <div class="list-item-content">
            {{ item.content }}
          </div>
        </div>
      </div>
    </a-spin>

  </div>
</template>
<script>
import {search} from "@/api/knowledge";

export default ({
  watch: {
    text: {
      handler(newVal) {
        this.search(newVal)
      },
      immediate: true
    }
  },
  props: {
    searchText: {
      type: String,
      default: ''
    },
    panelHeight: {
      type: Number,
      require: false
    }
  },
  data() {
    return {
      menus: [],
      spinning: false
    }
  },
  computed: {
    text: {
      get() {
        return this.searchText
      },
      set(newVal) {
        this.$emit('update:searchText', newVal)
      }
    }
  },
  methods: {
    clickPrompt(prompt) {
      this.$emit('clickPrompt', prompt)
    },
    search(text) {
      this.spinning = true
      search(text).then(res => {
        this.spinning = false
        if (res.data) {
          this.menus = res.data
        }
      }).finally(() => {
        this.spinning = false
      })
    },
  },
  mounted() {
    this.$refs.search.focus()
  }
})
</script>

<style scoped lang="scss">
.prompts-panel {
  .ant-input, .ant-select-selection {
    border-radius: 0;
    font-weight: normal;
  }

  .prompts-container {
    overflow-y: auto;

    .list {
      padding: 10px 20px;
      cursor: pointer;


      .title {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 5px;
      }

      .list-item-content {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: #999;
      }
    }

    .list:hover {
      background: rgba(83, 105, 118, 0.2);
      border-radius: 5px;
    }


  }
}
</style>