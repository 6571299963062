import request from "@/utils/request";
import {APICONFIG} from "@/utils/apiConfig";


/**
 * 查询APP字典接口
 */
export function knowledge() {
    return request({
        url: `${APICONFIG}/knowledge/center`,
        method: 'get',
    })
}

export function details(pid) {
    return request({
        url: `${APICONFIG}/knowledge/details/${pid}`,
        method: 'get',
    })
}

export function search(text) {
    return request({
        url: `${APICONFIG}/knowledge/search?text=${text}`,
        method: 'get',
    })
}

